import React from 'react';
import ReactDOM from 'react-dom/client';

// ROOT APP COMPONENT
import App from './App';
import PublicApp from "./PublicApp"
// SITE SETTINGS CONTEXT
import SettingsProvider from './contexts/settingsContext';

// ALL THIRD PARTY LIBRARIES CSS
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'nprogress/nprogress.css';
import 'react-quill/dist/quill.snow.css';
import 'simplebar-react/dist/simplebar.min.css';
import 'pure-react-carousel/dist/react-carousel.es.css';

import { useState , useEffect } from 'react';
import Loader  from "globalLoader";
import { getData, getSession , setSession  } from 'api-handlers/apiFunctions';


const rootElement = document.getElementById('root');
const root = ReactDOM.createRoot(rootElement);



const RootComponent = () => {
  const [isVerified, setIsVerified] = useState(null);

  useEffect(() => {
    const verifyDevToken = async () => {
      const devToken = getSession( process.env.REACT_APP_DEV_TOKEN_NAME );
      if (devToken) {
        const headers =  { 'X-Custom-Token': devToken }
        try {
          const result = await getData( process.env.REACT_APP_DEV_VERIFY_ENDPOINT , headers );
          if (result.success){
            setSession( process.env.REACT_APP_DEV_TOKEN_NAME , result.data.token)
            setIsVerified(true);
          }  
        }
        catch(error){
          console.log(error)          
        }
      } 
      else {
        setIsVerified(false);
      }
    };


    if( process.env.REACT_APP_MODE === "prod" ){ setIsVerified(true) }
    else{ verifyDevToken() }
  }, []);

  if (isVerified === null) {
    return <Loader />; // You can show a loader while verifying the token
  }

  return (
    
    // strict mode calls useEffect twice
    // <React.StrictMode>
    // </React.StrictMode>

    <SettingsProvider>
      {isVerified ? <App /> : <PublicApp />}
    </SettingsProvider>


  );
};

root.render(<RootComponent />);


