import { lazy } from 'react';
// import { Outlet } from 'react-router-dom';
// CUSTOM COMPONENTS
import Loadable from '../routes/Loadable';


const ComingSoon = Loadable(lazy(() => import('pages/coming-soon')));

export const PublicRoutes = [

{
  path: '/',
  element: <ComingSoon />
}, 

];