import { lazy } from 'react';
import Loadable from './Loadable';
import { AuthGuard  } from 'components/auth';
import useSettings from 'hooks/useSettings';
import LayoutV1 from 'layouts/layout-1';
import LayoutV2 from 'layouts/layout-2';


// Dashboard HomePage
const DashboardPage = Loadable(lazy(() => import('pages/dashboard/dashboard-home')));
// User & Roles Module
const AddNewUser = Loadable(lazy(() => import('pages/dashboard/users/add-new-user')));
const UpdateUser = Loadable(lazy(() => import('pages/dashboard/users/update-user')));
const UserListView = Loadable(lazy(() => import('pages/dashboard/users/user-list-1')));
const UserGridView = Loadable(lazy(() => import('pages/dashboard/users/user-grid-1')));
const RolesListView = Loadable(lazy(() => import('pages/dashboard/roles/roles-list-1')));
const RolesAddView = Loadable(lazy(() => import('pages/dashboard/roles/add-new-role')));
const RolesUpdateView = Loadable(lazy(() => import('pages/dashboard/roles/update-role')));
// Scraper & Jobs Module
const ScrapersListView = Loadable(lazy(() => import('pages/dashboard/scrapers/scrapers-list-1')));
const JobsListView = Loadable(lazy(() => import('pages/dashboard/jobs/jobs-list-1')));




const ActiveLayout = () => {
  const {
    settings
  } = useSettings();
  switch (settings.activeLayout) {
    case 'layout1':
      return <LayoutV1 />;
    case 'layout2':
      return <LayoutV2 />;
    default:
      return <LayoutV1 />;
  }
};
export const DashboardRoutes = [{
  path: 'dashboard',
  element: <AuthGuard>
        <ActiveLayout />
      </AuthGuard>,
  children: [
    {
      path: '',
      element: <DashboardPage />
    }, 
    {
      path: 'add-user',
      element: <AddNewUser />
    }, 
    {
      path : "update-user",
      element : <UpdateUser />
    },
    {
      path: 'users',
      element: <UserListView />
    }, 
    {
      path: 'users/view/grid',
      element: <UserGridView />
    }, 
    {
      path : "roles",
      element : <RolesListView />
    },
    {
      path : "add-role",
      element : <RolesAddView />
    },
    {
      path : "update-role",
      element : <RolesUpdateView />
    },

    {
      path : "scrapers",
      element : <ScrapersListView />
    },
    {
      path : "scraper/:scraperName" ,
      element : <JobsListView />
    }
  ]
}];




  //   {
  //   index: true,
  //   element: <Analytics />
  // }, 
  // {
  //   path: 'crm',
  //   element: <CRM />
  // }, {
  //   path: 'crm-2',
  //   element: <CRMV2 />
  // }, {
  //   path: 'sales',
  //   element: <Sales />
  // }, {
  //   path: 'sales-2',
  //   element: <SalesV2 />
  // }, {
  //   path: 'finance',
  //   element: <Finance />
  // }, {
  //   path: 'finance-2',
  //   element: <FinanceV2 />
  // }, 
  

  
  // {
  //   path: 'logistics',
  //   element: <Logistics />
  // }, {
  //   path: 'marketing',
  //   element: <Marketing />
  // }, {
  //   path: 'analytics-2',
  //   element: <AnalyticsV2 />
  // }, {
  //   path: 'learning-management',
  //   element: <LMS />
  // }, {
  //   path: 'job-management',
  //   element: <JobManagement />
  // }, 

  // {
  //   path: 'account',
  //   element: <Account />
  // }, {
  //   path: 'invoice-list',
  //   element: <InvoiceList />
  // }, {
  //   path: 'create-invoice',
  //   element: <InvoiceCreate />
  // }, {
  //   path: 'invoice-details',
  //   element: <InvoiceDetails />
  // }, {
  //   path: 'product-list',
  //   element: <ProductList />
  // }, {
  //   path: 'product-grid',
  //   element: <ProductGrid />
  // }, {
  //   path: 'create-product',
  //   element: <ProductCreate />
  // }, {
  //   path: 'product-details',
  //   element: <ProductDetails />
  // }, {
  //   path: 'cart',
  //   element: <Cart />
  // }, {
  //   path: 'payment',
  //   element: <Payment />
  // }, {
  //   path: 'billing-address',
  //   element: <BillingAddress />
  // }, {
  //   path: 'payment-complete',
  //   element: <PaymentComplete />
  // }, {
  //   path: 'profile',
  //   element: <Profile />
  // }, {
  //   path: 'data-table-1',
  //   element: <DataTable1 />
  // }, {
  //   path: 'about',
  //   element: <About />
  // }, {
  //   path: 'career',
  //   element: <Career />
  // }, {
  //   path: 'file-manager',
  //   element: <FileManager />
  // }, {
  //   path: 'support',
  //   element: <Support />
  // }, {
  //   path: 'create-ticket',
  //   element: <CreateTicket />
  // }, {
  //   path: 'chat',
  //   element: <Chat />
  // }, {
  //   path: 'todo-list',
  //   element: <TodoList />
  // }, {
  //   path: 'mail',
  //   children: [{
  //     path: 'all',
  //     element: <AllMail />
  //   }, {
  //     path: 'inbox',
  //     element: <Inbox />
  //   }, {
  //     path: 'sent',
  //     element: <Sent />
  //   }, {
  //     path: 'compose',
  //     element: <Compose />
  //   }, {
  //     path: 'details',
  //     element: <MailDetails />
  //   }]
  // }, {
  //   path: 'projects',
  //   children: [{
  //     path: 'version-1',
  //     element: <ProjectV1 />
  //   }, {
  //     path: 'version-2',
  //     element: <ProjectV2 />
  //   }, {
  //     path: 'version-3',
  //     element: <ProjectV3 />
  //   }, {
  //     path: 'details',
  //     element: <ProjectDetails />
  //   }, {
  //     path: 'team-member',
  //     element: <TeamMember />
  //   }]
  // }




 